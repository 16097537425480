<template>
  <div>
    {{ changeDocumentOrMeteoType }}
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  computed: {
    changeDocumentOrMeteoType() {
      const [return_type] = this.type.split(', ');

      return return_type;
    }
  }
};
</script>
